import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "wm-dialog_title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_wm_popup = _resolveComponent("wm-popup")!

  return (_openBlock(), _createElementBlock("div", {
    class: "wm-dialog_body",
    style: _normalizeStyle({visibility:_ctx.cfg.show?'inherit':'hidden'})
  }, [
    _createVNode(_component_wm_popup, {
      ref: "Popup",
      show: _ctx.cfg.show,
      "onUpdate:show": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.cfg.show) = $event)),
      width: "100%",
      height: "100%",
      position: "top",
      time: 600
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: "wm-dialog_bg",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.close(_ctx.isClose)))
        }),
        _createElementVNode("div", {
          class: "wm-dialog",
          style: _normalizeStyle({width:_ctx.width, height:_ctx.height, borderRadius:_ctx.borderRadius})
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("span", null, _toDisplayString(_ctx.title), 1),
            _createElementVNode("div", {
              class: "wm-dialog_close",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.close(true)))
            })
          ]),
          _createElementVNode("div", {
            class: "wm-dialog_content scrollbar",
            style: _normalizeStyle({overflow: _ctx.overflow, maxHeight: 'calc('+_ctx.cfg.height+'px - 46px - 40px - '+_ctx.bottom+')'})
          }, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ], 4),
          (_ctx.bottom)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "wm-dialog_bottom",
                style: _normalizeStyle({height:_ctx.bottom+'', lineHeight:_ctx.bottom+''})
              }, [
                _renderSlot(_ctx.$slots, "bottom", {}, undefined, true)
              ], 4))
            : _createCommentVNode("", true)
        ], 4)
      ]),
      _: 3
    }, 8, ["show"])
  ], 4))
}