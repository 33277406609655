import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["value", "placeholder", "maxlength", "disabled", "readonly"]
const _hoisted_3 = ["value", "type", "placeholder", "maxlength", "disabled", "readonly"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "wm-input_body",
    style: _normalizeStyle({width: _ctx.width, height: _ctx.height, margin: _ctx.margin})
  }, [
    (_ctx.value&&_ctx.clearable)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "wm-input_clear_body",
          style: _normalizeStyle({width: _ctx.lineHeight, height: _ctx.lineHeight}),
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.Clear()), ["stop"]))
        }, [
          _createElementVNode("div", {
            class: "wm-input_clear",
            style: _normalizeStyle({width: 'calc('+_ctx.lineHeight+' / 2)', height: 'calc('+_ctx.lineHeight+' / 2)'})
          }, null, 4)
        ], 4))
      : _createCommentVNode("", true),
    (_ctx.icon)
      ? (_openBlock(), _createElementBlock("i", {
          key: 1,
          class: _normalizeClass(["wm-input_text", _ctx.icon]),
          style: _normalizeStyle({
        width: _ctx.lineHeight,
        lineHeight: _ctx.lineHeight,
        fontSize: _ctx.iconSize,
        left: _ctx.iconAlign=='left'?'0':'',
        right: _ctx.iconAlign=='right'?'0':'',
        color: _ctx.iconColor,
        backgroundColor: _ctx.iconBgcolor,
        borderRadius: _ctx.iconRadius,
      }),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.iconClick()))
        }, null, 6))
      : _createCommentVNode("", true),
    (_ctx.text)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: "wm-input_text",
          innerHTML: _ctx.text,
          style: _normalizeStyle({
        padding: _ctx.textPadding,
        lineHeight: _ctx.lineHeight,
        left: _ctx.textAlign=='left'?'0':'',
        right: _ctx.textAlign=='right'?'0':'',
        color: _ctx.textColor,
        backgroundColor: _ctx.textBgcolor,
        borderRadius: _ctx.textRadius,
      }),
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.textClick()))
        }, null, 12, _hoisted_1))
      : _createCommentVNode("", true),
    (_ctx.textLen)
      ? (_openBlock(), _createElementBlock("div", {
          key: 3,
          class: "wm-input_total",
          style: _normalizeStyle({lineHeight:_ctx.lineHeight})
        }, _toDisplayString(_ctx.val_len) + "/" + _toDisplayString(_ctx.maxlength || '无限制'), 5))
      : _createCommentVNode("", true),
    (_ctx.type=='textarea')
      ? (_openBlock(), _createElementBlock("textarea", {
          key: 4,
          ref: "wmTextarea",
          class: "wm-input",
          value: _ctx.value,
          placeholder: _ctx.placeholder,
          maxlength: _ctx.maxlength,
          disabled: _ctx.disabled,
          readonly: _ctx.readonly,
          style: _normalizeStyle({
        height: _ctx.height,
        padding: _ctx.padding,
        lineHeight: _ctx.lineHeight,
      }),
          onInput: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.input && _ctx.input(...args))),
          onFocus: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.inputFocus && _ctx.inputFocus(...args))),
          onBlur: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.inputBlur && _ctx.inputBlur(...args)))
        }, null, 44, _hoisted_2))
      : (_openBlock(), _createElementBlock("input", {
          key: 5,
          ref: "wmInput",
          class: "wm-input",
          value: _ctx.value,
          type: _ctx.type,
          placeholder: _ctx.placeholder,
          maxlength: _ctx.maxlength,
          disabled: _ctx.disabled,
          readonly: _ctx.readonly,
          style: _normalizeStyle({
        height: _ctx.height,
        padding: _ctx.padding,
        lineHeight: _ctx.lineHeight,
      }),
          onInput: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.input && _ctx.input(...args))),
          onFocus: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.inputFocus && _ctx.inputFocus(...args))),
          onBlur: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.inputBlur && _ctx.inputBlur(...args)))
        }, null, 44, _hoisted_3))
  ], 4))
}