import { vShow as _vShow, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, vModelText as _vModelText, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "wm-login_body" }
const _hoisted_2 = { class: "wm-login_ct" }
const _hoisted_3 = {
  key: 0,
  class: "wm-login_form"
}
const _hoisted_4 = {
  key: 1,
  class: "wm-login_form"
}
const _hoisted_5 = {
  key: 2,
  class: "wm-login_form"
}
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "wm-login_bottom" }
const _hoisted_8 = {
  key: 0,
  class: "flex"
}
const _hoisted_9 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_wm_popup = _resolveComponent("wm-popup")!

  return (_openBlock(), _createElementBlock("div", {
    class: "wm-login_popup",
    style: _normalizeStyle({visibility:_ctx.login.show?'inherit':'hidden'})
  }, [
    _createVNode(_component_wm_popup, {
      show: _ctx.login.show,
      "onUpdate:show": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.login.show) = $event)),
      width: "100%",
      height: "100%",
      position: "top",
      time: 600
    }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", {
          class: _normalizeClass(["wm-login_bg", _ctx.login.bg])
        }, null, 2), [
          [_vShow, _ctx.login.show]
        ]),
        _cache[13] || (_cache[13] = _createElementVNode("div", { class: "wm-login_mask" }, null, -1)),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", {
              class: "wm-login_logo",
              style: _normalizeStyle({backgroundImage:'url('+(_ctx.login.uname&&_ctx.login.uname==_ctx.login.local_uname&&_ctx.login.img?_ctx.login.img:require('@/assets/logo.svg'))+')', backgroundSize:_ctx.login.uname&&_ctx.login.uname==_ctx.login.local_uname&&_ctx.login.img?'100%':'60%'})
            }, null, 4),
            (!_ctx.login.is_passwd)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _cache[10] || (_cache[10] = _createElementVNode("i", { class: "ui ui_user ico" }, null, -1)),
                  _withDirectives(_createElementVNode("input", {
                    ref: "loginUname",
                    type: "text",
                    class: "input",
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.login.uname) = $event)),
                    onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.clickLogin()), ["enter"])),
                    maxlength: "32",
                    placeholder: "用户名 / 手机号码 / 邮箱"
                  }, null, 544), [
                    [_vModelText, _ctx.login.uname]
                  ])
                ]))
              : (_ctx.login.is_passwd&&!_ctx.login.is_safety)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _cache[11] || (_cache[11] = _createElementVNode("i", { class: "ui ui_safety ico" }, null, -1)),
                    _withDirectives(_createElementVNode("input", {
                      ref: "loginPasswd",
                      type: "password",
                      class: "input",
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.login.passwd) = $event)),
                      onKeyup: _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.clickLogin()), ["enter"])),
                      maxlength: "32",
                      placeholder: "请输入密码"
                    }, null, 544), [
                      [_vModelText, _ctx.login.passwd]
                    ])
                  ]))
                : (_ctx.login.is_safety)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _cache[12] || (_cache[12] = _createElementVNode("i", { class: "ui ui_safety ico" }, null, -1)),
                      _createElementVNode("img", {
                        class: "vcode",
                        src: _ctx.login.vcode_url,
                        alt: "点击刷新",
                        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.changeVcode()))
                      }, null, 8, _hoisted_6),
                      _withDirectives(_createElementVNode("input", {
                        ref: "loginVcode",
                        type: "text",
                        class: "input",
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.login.vcode) = $event)),
                        onKeyup: _cache[6] || (_cache[6] = _withKeys(($event: any) => (_ctx.clickLogin()), ["enter"])),
                        maxlength: "4",
                        placeholder: "验证码"
                      }, null, 544), [
                        [_vModelText, _ctx.login.vcode]
                      ])
                    ]))
                  : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_7, [
              (_ctx.login.uname)
                ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
                    _createElementVNode("i", {
                      class: "ui ui_arrow_left",
                      onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.clearUser()))
                    }),
                    _createElementVNode("i", {
                      class: "ui ui_arrow_right",
                      onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.clickLogin()))
                    })
                  ]))
                : (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.copy), 1))
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["show"])
  ], 4))
}