import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueDatePicker = _resolveComponent("VueDatePicker")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({width: _ctx.width, height: _ctx.height})
  }, [
    _createVNode(_component_VueDatePicker, {
      modelValue: _ctx.date,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.date) = $event)),
      mode: "range",
      format: _ctx.format,
      range: _ctx.range,
      placeholder: _ctx.placeholder,
      locale: _ctx.language,
      "day-names": _ctx.dayName,
      "enable-time-picker": _ctx.isTime,
      "text-input": _ctx.isText,
      "multi-calendars": _ctx.isMulti,
      "min-date": _ctx.minDate,
      "max-date": _ctx.maxDate,
      "auto-apply": ""
    }, null, 8, ["modelValue", "format", "range", "placeholder", "locale", "day-names", "enable-time-picker", "text-input", "multi-calendars", "min-date", "max-date"])
  ], 4))
}