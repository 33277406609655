import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  ref: "chart",
  class: "wm-chart_pie"
}
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.html)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "wm-chart_pie_html",
          innerHTML: _ctx.html
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true)
  ], 512))
}