import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "wm-main_body",
    style: _normalizeStyle({
      width:'calc('+_ctx.width+' - '+_ctx.paddingX+' * 2)',
      height:'calc('+_ctx.height+' - '+_ctx.paddingY+' * 2)',
      lineHeight: _ctx.lineHeight,
      backgroundColor: _ctx.bgColor,
    })
  }, [
    _createElementVNode("div", {
      class: "wm-main_ct scrollbar",
      style: _normalizeStyle({
      width: _ctx.width,
      height: _ctx.height,
      padding: _ctx.paddingY+' '+_ctx.paddingX,
      overflowX: _ctx.overflowX,
      overflowY: _ctx.overflowY,
    })
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 4)
  ], 4))
}