import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["wm-button", _ctx.effect+'_'+_ctx.type]),
    style: _normalizeStyle({
      width: _ctx.width,
      height: _ctx.height,
      lineHeight: _ctx.height,
      padding: _ctx.padding,
      margin: _ctx.margin,
      borderRadius: _ctx.radius,
      fontSize: _ctx.fontSize,
    }),
    disabled: _ctx.disabled
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createElementBlock("i", {
          key: 0,
          class: _normalizeClass(_ctx.icon),
          style: _normalizeStyle({fontSize: _ctx.iconSize, lineHeight: 'calc('+_ctx.height+' - 2px)'})
        }, null, 6))
      : _createCommentVNode("", true),
    _createElementVNode("span", null, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ], 14, _hoisted_1))
}