import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "wm-radio" }
const _hoisted_2 = {
  key: 0,
  class: "flex disabled"
}
const _hoisted_3 = { class: "label" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (v, k) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: k }, [
        (v.disabled)
          ? (_openBlock(), _createElementBlock("li", _hoisted_2, [
              _cache[0] || (_cache[0] = _createElementVNode("span", { class: "checked" }, null, -1)),
              _createElementVNode("span", _hoisted_3, _toDisplayString(v.label), 1)
            ]))
          : (_openBlock(), _createElementBlock("li", {
              key: 1,
              class: "enabled",
              onClick: _withModifiers(($event: any) => (_ctx.clickRadio(k)), ["stop"])
            }, [
              _createElementVNode("span", {
                class: _normalizeClass(["checked", v.value==_ctx.value?'active':''])
              }, null, 2),
              _createElementVNode("span", _hoisted_5, _toDisplayString(v.label), 1)
            ], 8, _hoisted_4))
      ], 64))
    }), 128))
  ]))
}